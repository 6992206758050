<template>
    <div>
      <v-card class="rounded-lg" elevation="0">
        <v-tabs fixed-tabs background-color="#50c78f" dark>
          <v-tabs-slider color="#b1e3cc"></v-tabs-slider>
            <v-tab>PACIENTES EM BUSCA ATIVA</v-tab>
 
            <v-tab-item>
              <v-card-text>
                <v-form @submit.prevent="search(donorSearch)" ref="searchDonor">
                  <p class="space-personalized"></p>
                  <v-container>
                    <v-row>
                      
                      <v-col class="d-flex" cols="12" sm="">
                        <v-text-field
                        color="#086318" 
                        label="Nome" 
                        placeholder="Nome completo" 
                        outlined 
                        v-model="donorSearch.name"
                        dense></v-text-field>
                      </v-col>

                      <v-col class="d-flex" cols="12" sm="2">
                        <v-text-field
                        color="#086318" 
                        label="CPF" 
                        placeholder="CPF" 
                        outlined 
                        v-model="donorSearch.cpf"
                        maxlength="14" 
                        v-mask="'###########'"
                        dense></v-text-field>
                      </v-col>
                      
                      <v-btn class="rounded-lg" elevation="1" color="blue" @click="search()">
                          <span class="textBtn"> Buscar </span>
                      </v-btn> 

                      <div class="space"></div>

                      <v-btn class="rounded-lg" elevation="1" color="grey" @click="clear()">
                          <span class="textBtn"> Limpar </span>
                      </v-btn>

                      <div class="space"></div>

                      <v-btn class="rounded-lg" elevation="1" color="#50c78f" @click="$router.push('/cadastrar-busca-ativa')" v-if="((this.$store.state.profile_id != 5))">
                        <v-icon class="mr-2" color="#FFFFFF">mdi-hospital</v-icon>
                        <span class="textBtn"> Nova Busca Ativa </span>
                      </v-btn>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>
  
              <!-- Início da Tabela -->
              <v-data-table
                :headers="headers"
                :items="donor"
                :footer-props="{'items-per-page-text': 'Pacientes por página',}"
                :page="page"
                :loading="loading">

                <template v-slot:[`item.value`]="{ item }">
                  <TagStatus :resource="item"/>
                </template>
        
                <template v-slot:[`item.actions`]="{ item }" :resource="item">
                  <v-tooltip left color="#50c78f">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon 
                        size="20"
                        class="icon"
                        color="#50c78f" 
                        v-on="on"
                        v-bind="attrs"
                        align-item="center"
                      @click="$router.push({path: '/editar-busca-ativa', query:{item:item}})">mdi-chat-plus 
                      </v-icon>
                    </template>
                      <span style="color:white">Adicionar Escala</span>
                    </v-tooltip>
                </template>
                
                
                <template v-slot:[`item.cpf`]="{ item }">
                  {{ item.cpf | VMask("###.###.###-##") }}
                </template>
        
              </v-data-table>
           </v-tab-item>
      </v-tabs>
  </v-card>
  </div>
  </template>
  
  <script>
  import axios from "@/axios";
  import TagStatus from './TagStatus-comp.vue';
  
  export default {
    data() {
      return {
        dialog: false,
        headers: [
          {
            text: "Paciente",
            align: "center",
            value: "name",
          },
          {
            text: "Diagnóstico Inicial",
            align: "center",
            value: "initial_diagnosis",
          },
          {
            text: "CPF",
            align: "center",
            value: "cpf",
          },
          {
            text: "Status",
            align: "center",
            value: "value",
          },
          { text: "Nova Escala de Glasgow", value: "actions", align: "center", sortable: false },
        ],

        donorSearch: {
          name:"",
          cpf:""
        },

        select: {
          state:'Vivo(a)',
          id:1
        },

        items: [
          {
            state: 'Vivo(a)',
            id: 1
          },
          {
            state: 'Morto(a)',
            id: 2
          }
        ],
  
        displayError: {
          display: false,
          message: [],
        },
        
        totalDonors: " ",
        page: 1,
        totalUsers: 0,
        numberOfPages: 0,
        passengers: [],
        loading: true,
        options: {},
        sortBy: "name",
        filterUnity: "",
        filtro: {},
        donor: [],
        usuarios: [],
        unidades: [],
        hospital: [],
        message: "",
        snackbar: false,
        loadingBTN: false,
        exibirMensagem: false,
        messageError: "",
      };
    },
  
    components: {
      TagStatus
    },

    watch: {
      sortBy: {
        handler() {
  
        }
      },
      options: {
        handler() {
          this.indoDataTable();
        },
      },
      deep: true,
    },
  
    methods: {
  
      indoDataTable() {
        this.loading = true;

        if(this.$store.state.profile_id == 1){
          axios.get(`/donor`).then((response) => {
            this.loading = false;
            this.donor = response.data.data;
            this.donor = this.donor.filter((x)=> x.initial_diagnosis != null && x.is_donor != 'não')
          });
        }else{
          const id = this.$store.state.unity_id
          axios.get(`/donor/donorUnity/${id}`).then((response) => {
            this.loading = false;
            this.donor = response.data.data
            this.donor = this.donor.filter((x)=> x.initial_diagnosis != null && x.is_donor != 'não')
          })
        }
      },
  
      search() {
        var filtro = (this.donorSearch)
        if(this.$store.state.profile_id == 1){
            axios.post("/donor/active_search", filtro).then((response) => {
              this.loading = true;
              this.donor = response.data; 
              this.loading = false;
            });
        }else{
            axios.post("/donor/active_search", filtro).then((response) => {
              this.loading = true;
              this.donor = response.data; 
              this.donor = this.donor.filter((x)=> x.unity_id == this.$store.state.unity_id);
              this.loading = false;
            });
        }
      },
      
      openCreateDoador() {
        this.dialog = true;
      },

      clear(){
        this.donorSearch.name = null,
        this.donorSearch.cpf = null,
        this.indoDataTable();
        this.$emit("eventname", '');
        },

    },
  
    mounted() {
      this.indoDataTable();
    },
    
  };
  </script>
  
  <style scoped>
  .header-table {
    display: flex;
    margin-bottom: 20px;
  }

  .d-flex{
    padding: 0 6px 0 0;
  }
  
  .space {
    width: 5px;
  }

  .space-personalized{
  padding: 9px;
  }

  .spacediferent {
  width: 7px;
}
  .v-btn:not(.v-btn--round).v-size--default {
    height: 40px !important;
  }
  
  .textBtn {
    font-family: "Poppins";
    color: #ffffff;
  }
  
  .create-user-dialog {
    padding: 20px;
  }
  
  .card {
    padding: 35px;
  }
  
  .banner {
    background: #b3ffab; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to right,
      #27ae60,
      #1abc9c
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to right,
      #ee106d,
      #e46987
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
    border-radius: 8px;
  
    margin-bottom: 20px;
  
    display: flex;
    align-items: center;
  }
  
  .icon{

  }

  .banner > h4 {
    color: #ffffff;
    padding: 10px;
  }
  
  .cardFooter {
    padding: 0 !important;
  }
  </style>
  