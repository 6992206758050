<template>
    <v-chip
      :color="setStatus.bgColor"
      :text-color="setStatus.textColor"
    >
      <span class="status__title">
        {{ setStatus.title }}
      </span>
    </v-chip>
  </template>

  <script>
  export default {
    props: {
      resource: {
        type: Object,
      },
    },
  
    computed: {
      setStatus() {
        return this.resource.kind_of_donor_id == 2
          ? { bgColor: "#70dba9", textColor: "#70dba9", title: "Doador" }
          : { bgColor: "#f0ebe9", textColor: "#FFFFFF", title: "Paciente" };
      },
    },
  };
  </script>
  
  <style lang="scss">
  .status__title {
    font-weight: 600;
  }
  </style>